import React from 'react'
import logo from "../assets/images/hollajobslogo.png";
import instagram from "../assets/images/iconfinder_3225191_app_instagram_logo_media_popular_icon_512px.png";
import facebook from "../assets/images/iconfinder_107175_circle_facebook_icon_512px.png";
import twitter from "../assets/images/iconfinder_294709_circle_twitter_icon_512px.png";
import { Link } from "react-router-dom";

function Footer() {
   return (
      <footer className="page-footer font-small teal pt-4 px-5">
         <div className="container-fluid">
            <div className="row">
               <div className="col-md-6 mt-md-0 mt-3 text-sm-start text-center">
                  <Link className="navbar-brand" to='/'>
                     <img src={logo} width="101" height="54" className="d-inline-block align-top text-center" alt="" />
                  </Link>
                  <p className='fw-bold mt-5'></p>
                  <p className='fw-bold'></p>
                  <p className='fw-bold'>
                     <a href="/privacy" className="copy-right fw-bold">Policy Statements</a>
                  </p>
                  <p className='fw-bold'>
                     <a href="/terms" className="copy-right fw-bold">Terms & Conditions</a>
                  </p>

               </div>
               <div className="col-md-6 mt-md-0 mt-3 ">
                  <div className='d-flex justify-content-sm-end align-items-center justify-content-center'>
                     <img src={instagram} className='social-icon' alt="" />
                     <img src={facebook} alt="" className='ml-3 social-icon2' style={{ marginLeft: '15px' }} />
                     <img src={twitter} alt="" className='ml-3 social-icon' style={{ marginLeft: '15px' }} />
                  </div>
                  <div className='text-sm-end text-center'>
                     <p className='fw-bolder mt-5'>Address</p>
                     <p>Plot 3, Tony Oghenejode Close, </p>
                     <p>Off Omorinre Johnson Street</p>
                     <p>Lekki Phase 1, Lagos</p>
                  </div>
               </div>
            </div>
         </div>
         <hr className="clearfix w-100" />
         <div className='row pb-3'>
            <a className="copy-right col-sm-6 fw-bold">Copyright © - All Rights Reserved ® - 2020 Hollajobs
            </a>
            <div className='col-sm-6 d-flex justify-content-sm-end'>
               <a href="/privacy" className="copy-right px-sm-5 fw-bold">Policy Statements</a>
               <a href="/terms" className="copy-right fw-bold">Terms & Conditions</a>
            </div>
         </div>
      </footer>
   );
}

export default Footer;
