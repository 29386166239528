import React from 'react'
import Header from "../components/header";
import Footer from "./footer";

function DefaultLayout({ children }) {
   return (
      <div>
         <Header />
         <div className='con-fluid'>
            {/* <div className='container-fluid' style={{marginTop: '80px'}}> */}
            {children}
         </div>
         <Footer />
      </div>
   );
}

export default DefaultLayout;
