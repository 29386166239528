import React from 'react'
import hollaApp from '../assets/images/hollaapp2.jpg'
// import hollaApp from '../assets/images/hollaapp2.png'
import neeHolla from '../assets/images/hollaclient2.png'
import neeHollaworker from '../assets/images/hollaworker2.png'
import safe from '../assets/images/safe.png'
import unlimited from '../assets/images/unlimited.png'
import affordable from '../assets/images/affordable.png'
import money from '../assets/images/money.png'
import work from '../assets/images/workpng.png'
import coin from '../assets/images/coin.png'
import mark from '../assets/images/markicon.png'
import applestore from '../assets/images/applystore.png'
import playstore from '../assets/images/playstore.png'
import hollamoney from '../assets/images/hollamoney.png'
import hollavend from '../assets/images/hollavend.png'
import cleintphone from '../assets/images/cleintphone.png'
import workerphone from '../assets/images/workerphone.png'
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";

const showSurvey = () => {
   document.querySelector('.survey-section').classList.toggle('d-none')
}

function Home() {
   return (
      <div>
         <section>
            <div className='row p-sm-5'>
               <div className='col-lg-6 p-sm-0'>
                  <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                     <div className='header1 fw-bold'>
                        Get artisan services near you  at affordable rates
                     </div>
                     <div className='header2 mt-4'>
                        As a Holla worker you get unlimited access to clients near your location.
                     </div>
                  </Animated>
                  <Animated animationIn="fadeInLeftBig" animationOut="fadeOut">
                     <div className='d-flex align-items-lg-center mt-sm-5 pt-5 hero-button'>
                        <a href='//app.hollajobs.com/register' className='btn btn-warning pre-reg-btn py-3 bg-base-secondary fw-bold'>
                           Register Now
                        </a>
                        <div style={{ position: 'relative' }}>
                           <ul className="survey-section d-none">
                              <li>
                                 <a href="https://forms.gle/MCVYub1HjLFTVyTM9">Client Survey</a>
                              </li>
                              <li>
                                 <a href="https://forms.gle/yN81aey78nxCGK9BA">Worker Survey</a>
                              </li>
                           </ul>
                           <a
                              //onClick={showSurvey} 
                              href='//app.hollajobs.com'
                              className='btn btn-outline-primary pre-reg-btn take-suv py-3 fw-bold' style={{ marginLeft: '40px' }}>
                              LOGIN
                           </a>
                        </div>
                     </div>
                  </Animated>
               </div>
               <div className='col-lg-6 px-sm-5 small-media-20'>
                  <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={true}>
                     <img src={hollaApp} className="hero-phone" alt="" />
                  </Animated>
               </div>
            </div>
         </section>

         <section style={{ marginTop: '200px' }}>
            <div className='row bg-base-primary client-container'>
               <div className='col-md-6 small-media-center'>
                  <img src={cleintphone} className="client-phone" alt="" />
               </div>
               <div className='col-md-6 why-need-client h-50 d-flex align-items-center justify-content-center h-100'>
                  <Animated animationIn="fadeInDown" animationOut="fadeOut">

                     As a Client
                  </Animated>
               </div>
            </div>
            <div className='row px-sm-5 bg-base-lightblue py-5'>
               <div className='col-lg-4 px-5'>
                  <div className='bg-base-lightblue'>
                     <div className='small-media-20'>
                        <img src={safe}
                           className="img-fluid" alt="" style={{ height: ' 71px', width: '71px' }} />
                     </div>
                     <div className='card-base-header mt-4'>
                        Safe and convenient service
                     </div>
                     <div className='card-base-body mt-4'>
                        Safety is our priority, we offer insurance to our clients (terms and conditions apply) to ensure satisfaction.
                        {/* Safety is our priority. Although all our workers are trained service providers who prioritize safety and provide services in safe and convenient conditions at the satisfaction of our clients, we offer insurance to our clients (terms and conditions apply) to ensure satisfaction. */}
                     </div>
                  </div>
               </div>
               <div className='col-lg-4 px-5'>
                  <div className='bg-base-lightblue'>
                     <div className='small-media-20'>
                        <img src={unlimited}
                           className="img-fluid" alt="" style={{ height: ' 71px', width: '71px' }} />
                     </div>
                     <div className='card-base-header mt-4'>
                        Unlimited artisan services with speed
                     </div>
                     <div className='card-base-body mt-4'>
                        Your service needs will be met with speed because we connect you to workers close to your location.
                     </div>
                  </div>
               </div>
               <div className='col-lg-4 px-5'>
                  <div className='bg-base-lightblue'>
                     <div className='small-media-20'>
                        <img src={affordable}
                           className="img-fluid" alt="" style={{ height: ' 71px', width: '71px' }} />
                     </div>
                     <div className='card-base-header mt-4'>
                        Quality services at affordable rates
                     </div>
                     <div className='card-base-body mt-4 small-media-pb-20'>
                        You won’t have to worry about the quality of our service because all our workers are experienced.
                        {/* You won’t have to worry about the quality of our service because all our workers are experienced professionals offering services at affordable rates. */}
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section style={{ marginTop: '200px' }}>
            <div className='row bg-base-secondary client-container'>
               <div className='col-md-6 why-need h-50 d-flex align-items-center justify-content-center h-100 text-dark order-2 order-md-1 why-need-worker'>
                  <Animated animationIn="fadeInDown">
                     As a Worker
                  </Animated>
               </div>
               <div className='col-md-6 order-1 small-media-center'>
                  <img src={workerphone} className="client-phone" alt="" />
               </div>
            </div>
            <div className='row px-sm-5 bg-base-lightyellow py-5' >
               <div className='col-lg-4 px-5'>
                  <div className='bg-base-lightyellow'>
                     <div className='small-media-20'>
                        <img src={coin}
                           className="img-fluid" alt="" style={{ height: ' 71px', width: '71px' }} />
                     </div>
                     <div className='card-base-header2 mt-4'>
                        Unlimited access to paying clients
                     </div>
                     <div className='card-base-body mt-4'>
                        Holla connects you to your specific target audience. Clients who need your service and either live, work or require your service around your location.
                     </div>
                  </div>
               </div>
               <div className='col-lg-4 px-5'>
                  <div className='bg-base-lightyellow'>
                     <div className='small-media-20'>
                        <img src={work}
                           className="img-fluid" alt="" style={{ height: ' 71px', width: '71px' }} />
                     </div>
                     <div className='card-base-header2 mt-4'>
                        Enjoy a better work experience
                     </div>
                     <div className='card-base-body mt-4'>
                        {/* Join a community of workers that enjoy a fulfilling work experience through an App that offers transparency to its workers.track the progress of all your jobs while they’re in progress, guaranteed accountability from Holla and Holla clients, and unlimited support from the Holla team to provide a convenient work experience for you. */}
                        Join a community of workers that enjoy a fulfilling work experience through an App that offers transparency to its workers.
                     </div>
                  </div>
               </div>
               <div className='col-lg-4 px-5 '>
                  <div className='bg-base-lightyellow'>
                     <div className='small-media-20'>
                        <img src={money}
                           className="img-fluid" alt="" style={{ height: ' 71px', width: '71px' }} />
                     </div>
                     <div className='card-base-header2 mt-4'>
                        Make more money while spending less
                     </div>
                     <div className='card-base-body mt-4 small-media-pb-20'>
                        Reduce your service expenses through various means such as; cutting down transportation costs and attending to clients within your location.
                        {/* Reduce your service expenses through various means such as; cutting down transportation costs and attending to clients within your location. at good rates. No additional charges for job Ads, just rely on intelligent client/worker matching from the Holla App. Enjoy exclusive access to Holla discount and insurance deals on work tools and life assurance to simplify service delivery processes. */}
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section style={{ marginTop: '50px' }}>
            <div className='py-sm-5 px-sm-5 be-first'>
               <div className='text-center'>
                  Be the first to know when we launch
               </div>
               <div className='text-center mt-5'>
                  <a href='//app.hollajobs.com/register' className='btn btn-warning pre-reg-btn px-sm-5 py-sm-3 bg-base-secondary fw-bold'>
                     Register Now
                  </a>
               </div>
            </div>
         </section>

         <section>
            <div className='py-sm-5 how-it-work bg-white text-center'>
               <div>
                  How it works
               </div>
            </div>
         </section>

         <section>
            <div className='row'
            // data-aos="zoom-in"
            >
               <div className='col-lg-6 bg-base-lightyellow p-0'>
                  <img src={neeHollaworker} className="w-100 img-fluid" alt="" />
                  <div className='d-list'>
                     <div className='worker-header'>
                        Worker
                     </div>
                     <div className='mt-sm-5'>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Download Holla
                           </div>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Sign up with required details
                           </div>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Indicate to become a worker
                           </div>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Complete worker registration
                           </div>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Wait to receive approval
                           </div>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Start getting job requests
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className='col-lg-6 bg-base-lightblue p-0'>
                  <img src={neeHolla} className="w-100 img-fluid" alt="" />
                  <div className='d-list'>
                     <div className='client-header'>
                        Client
                     </div>
                     <div className='mt-sm-5'>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Download Holla
                           </div>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Sign up with required details
                           </div>
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                           <img src={mark} className="img-fluid img-mark" alt="" />
                           <div className='list-item-point'>
                              Start requesting for workers
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className='coming-soon text-center mt-5'>
               Coming soon to
            </div>
            <div className='d-flex align-items-center justify-content-center mt-3'>
               <div>
                  <img src={playstore} className="img-fluid px-2" alt="" style={{ width: ' 228.67px', height: '100.33px' }} />

               </div>
               <div>
                  <img src={applestore} className="img-fluid px-2" alt="" style={{ width: '224.15px', height: '67.79px' }} />

               </div>
            </div>
         </section>

         {/* <section>
            <div className='other-product-header'>Other Products</div>
            <div className='row px-sm-5'>
               <div className='col-lg-6 p-sm-5'>
                  <div className='p-sm-5 p-3' style={{ backgroundColor: '#F6F6F6' }}>
                     <div>
                        <img src={hollamoney}
                           className="img-fluid img-other1" alt="" />
                     </div>
                     <div className='card-base-body mt-4'>
                        A community of business professionals, consultants and experienced workers. Supporting one another with empowering
                        resources, tools and valuable insights on best practices for their individual practices.
                     </div>
                     <div className='card-base-header mt-4 text-center'>
                        Coming soon
                     </div>
                  </div>
               </div>
               <div className='col-lg-6 p-sm-5 small-media-20'>
                  <div className='p-sm-5 p-3' style={{ backgroundColor: '#F6F6F6' }}>
                     <div>
                        <img src={hollavend}
                           className="img-fluid" alt="" style={{ height: ' 52px', width: '256px' }} />
                     </div>
                     <div className='card-base-body mt-4'>
                        A reliable airtime and data vending platform. Created to simplify the process of getting data packages for worker and client consumption.
                     </div>
                     <div className='card-base-header mt-5 text-center pt-sm-4'>
                        Coming soon
                     </div>
                  </div>
               </div>
            </div>
         </section> */}

      </div>
   );
}

export default Home;
