import React from 'react'


export default () => (
   <section className="bg-white">
      <div className="container py-5">
         <h3>PRIVACY AND POLICY</h3>
         <small>Last modified: July 13, 2020</small>
         <div className="privacy_policy">
            <p>
               Hollajobs Limited (Hollajobs) respect the security and privacy of your data and Intellectual Property (IP). Therefore, our Private Policy helps to explain our practices around the information we collect and how it affects you, what we do with the information; who we share the information with and the steps we take to protect your privacy.
               <br />
               This Privacy Policy shall be read along with our Terms & Conditions and the polices within this document applies to all of our product, apps, software, add-on, extension, website, online services, licence code, features, functionality, content or service unless specified otherwise.
            </p>
            <p>
               Hollajobs may review and modify these policies from time to time to reflect changes in law or to our services, however, the most current version will always be available on our Hollajobs website. By continuing to use or access Hollajobs App or the site after the revisions come into effect, you agree to be bound by the revised polices.
               We believe it should be easy for you to understand our privacy practices. That’s why we’re launching an updated and streamlined Privacy Policy that explains in clear language what information we collect, how we use it, and the choices and controls you have. We’re also publishing FAQs and other resources to give you more details about our privacy practices/policies.
            </p>
            <p>
               Hollajobs cannot transmit data about a user without obtaining user's permission and providing the user with access to information about how and where the data will be used. We don’t sell to third parties; and we only use it as this privacy statement/policy describes.
            </p>

            <div>
               <h4> 1. The Information We Collect</h4>
               <h6>a. Sign-up Information</h6>
               <p>During the initial sign-up, Hollajobs will require you to provide us with some personal information to register you to an account such as Email, Phone number, password, location (Optional) and subscribers profile picture (Optional). Some of these information’s are compulsory to enable us uniquely identify Hollajobs APP subscribers.</p>

               <h6>b. During usage and Device Information</h6>
               <p>Further information that will be captured will be from your device or during the use of our services. Some of these information’s will be captured automatically through our server such as, mobile phone number, email, location etc.</p>

               <h6>c. KYC Process Information</h6>
               <p>Once the user has been signed-up, the provided information will be used to activate the user via Hollajobs sign-up with the Know Your Customer (KYC) process. Once these information’s have been collated, they are stored securely waiting for the next course of action.</p>

               <h6>d. Credit Card Information</h6>
               <p>When subscribing directly from Hollajobs App, users will be required to use a payment gateway
                  to purchase their agreed services with the worker. During the payment process, Hollajobs uses a
                  trusted third party (United Payment Services-UPS) to collect information such as Personal Bank
                  transfer account details, credit/debit card number, expiry date, the security code, and the billing
                  address information. Note that our agent has a limited information of the payment process
                  information is not available to any of our agent.</p>

               <h6>e. Accessing information:</h6>
               <p>Users/subscribers will be able to view or contest the accuracy of personal data collected by
                  Hollajobs.</p>

               <h6>f. Security</h6>
               <p>Hollajobs App is entirely responsible for the accuracy and security by keeping it properly
                  away from unauthorized eyes and hands of the collected personal information. (Encryption
                  algorithm)</p>
            </div>

            <div>
               <h4>2. What We Do With the Information</h4>
               <p>We may use your information for the following manner; to protect our subscriber’s mobile
                  devices; to improve our product and services; to promote our services and develop new services;
                  and to create de-identified or aggregate information about our users. For instance</p>
               <ul>
                  <li>We may use your information to provide the services you have requested;</li>
                  <li>We may use aggregated non-personal information for auditing, conduct market research,
                     surveys, contests and sweepstakes;</li>
                  <li>We may use your provided email address or mobile number to send privacy or security
                     related notices and notify you of major Hollajobs services change;</li>
                  <li>We may use your provided email address and mobile number to communicate about
                     product announcement, special promotions from Hollajobs, or to administer
                     participation in special events;</li>
                  <li>We may use your information to engage in joint promotional activities with companies
                     that have products that can add value to Hollajobs or our services</li>
                  <li>We may use the data gathered to help towards development of new services or
                     improve your existing services.</li>
                  <li>We may send users emails about special offers, new services, updates of our
                     Hollajobs Apps or other information that may be interesting for users.</li>
                  <li>We may use customers data to provide personalised services unique to individual
                     users.</li>
               </ul>
            </div>

            <div>
               <h4>3. Who We Do and Don’t Share Your Information With</h4>
               <p>We will not sell or share any of your personal information with other third parties unless
                  they are directly involved in the recovery of assets, such as the law enforcement<b> agencies or
                     when we have your consent to do so. </b>
                  By Hollajobs using the Store to distribute Products, Hollajobs will protect the privacy and
                  legal rights of users. The Information the users provide Hopllajobs with, or Hollajobs App
                  Product accesses or uses, user names, passwords, or other login information or personal
                  information, Hollajobs will make the users aware that the information will be available to
                  Hollajobs Product, and will provide a legally adequate privacy notice and protection for
                  those users.</p>
            </div>
            <div>
               <h4>4. What We Cannot Guarantee</h4>
               <ul>
                  <li>We cannot guarantee data protection of users/app outside the Hollajobs APP except
                     confirmed and verified by us.</li>
               </ul>
               <h6>By subscribing to and activating “Hollajobs APP” I agree that:</h6>
               <ul>
                  <li>I have read and accepted the User Agreement and Privacy Policy</li>
                  <li> I may receive communications from Hollajobs and can change my notification
                     preferences on Hollajobs website/APP user interface.</li>
               </ul>
            </div>
            <div>
               If you have any questions about these Privacy Policies, do not hesitate to contact us as
               follows:
               <br />Email: contact@hollajobs.com
               <br />Telephone: +234906 800 0095 and/or +234906 800 0094
            </div>


         </div>
      </div>
   </section>

)