import React from 'react'



function ContactUs() {
   return (
      <div className='d-flex align-items-center justify-content-center'>
         <div className='input-container-width'>
            <div className='why-need text-center  text-dark'>
               Contact Us
            </div>
            <form className='mt-5'>
               <div className="form-group">
                  <label>Name</label>
                  <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="Name" />
               </div>
               <div className="form-group mt-3">
                  <label>Email address</label>
                  <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="Email address" />
               </div>
               <div className="form-group mt-3">
                  <label>Phone Number</label>
                  <input type="text" className="form-control" placeholder="Phone Number" />
               </div>
               <div className="form-group mt-3 mb-5">
                  <label htmlFor="comment">Comment</label>
                  <textarea className="form-control" rows="5" id="comment" placeholder='Type your message here'></textarea>
               </div>
               {/*<div className="form-check mt-3 invisible">*/}
               {/*    <input type="checkbox" className="form-check-input" />*/}
               {/*    <label className="form-check-label">Yes, I want to receive newsletter and I know that I can unsubscribe anytime.</label>*/}
               {/*</div>*/}
               <button className='btn btn-warning pre-reg-btn px-sm-5 py-sm-3 bg-base-secondary w-100 mt-3 fw-bold'>
                  Send
               </button>
            </form>
         </div>
      </div>
   );
}

export default ContactUs;
