import React from 'react'


const Registration = (e) => {
   e.preventDefault()
   let { fname, email, phone, user_type } = e.target.elements
   if (fname.value === "" || email.value === "" || phone.value === "" || user_type.value === "") {
      return document.querySelector('.error').textContent = 'Some fields are missing';
   }
   fname = fname.value.trim()
   email = email.value.trim()
   phone = phone.value.trim()
   user_type = user_type.value.trim()

   if (!/^[a-z\- ]+$/i.test(fname)) {
      return document.querySelector('.error').textContent = 'Use only alphabets for name';
   }
   // check phone
   if (phone.length !== 11) {
      return document.querySelector('.error').textContent = '11 digits required for phone number';
   }
   if (isNaN(phone)) {
      return document.querySelector('.error').textContent = 'Invalid phone number';
   }
   document.querySelector('#submit_btn').textContent = 'Loading...';
   // let url = 'http://localhost:8080/app/v1/auth/preRegister';
   let url = 'https://api.hollajobs.com/userservice/auth/pre-register-user';

   let dataSubmit = {
      name: fname, email, phone, user_type
   }
   document.querySelector('.error').textContent = ''
   // submiting the data
   fetch(url, {
      method: "POST",
      body: JSON.stringify(dataSubmit),
      headers: {
         'authorization': 'Bearer 000000000000000000000000000000',
      }
   }).then(data => data.json())
      .then(res => {
         if (res.status === "success") {
            if (user_type === 'worker') {
               document.querySelector('.modal-container').innerHTML = `<div class="text-center mt-4">
               <h3 class="text-green text-center">Registration Successful!</h3>
               <p class="mt-3 text-center">Thank you for your interest in Holla.<br />You will be redirected in 2 seconds to complete your registration</p>
               </div>`
               setTimeout(() => {
                  window.location.href = 'https://airtable.com/shrY3o8iWWVR4CWin'
               }, 3000);
            } else {
               document.querySelector('.modal-container').innerHTML = `<div class="text-center mt-4">
            <h3 class="text-green text-center">Registration Successful!</h3>
            <p class="mt-3 text-center">Thank you for your interest in Holla.</p>
         </div>`
            }
         } else if (res.error) {
            document.querySelector('#submit_btn').textContent = 'Register';
            document.querySelector('.error').textContent = res.error;
         } else {
            document.querySelector('#submit_btn').textContent = 'Register';
            document.querySelector('.error').textContent = 'Something went wrong please try again later';
         }
      }).catch(() => {
         document.querySelector('#submit_btn').textContent = 'Register';
         document.querySelector('.error').textContent = 'Something went wrong please try again later';
      })
}



function PreRegister() {
   return (
      <div className='d-flex align-items-center justify-content-center'>
         <div>
            <div className='why-need text-center  text-dark'>
               Preregister
            </div>
            <div className='header2 mt-3 text-center fw-bold' style={{ fontSize: '16px' }}>
               Thank you for your interest in Holla, please enter your details below.
               <div className="error text-red" style={{ color: 'red', marginTop: 10 }}></div>
            </div>
            <form onSubmit={Registration} className="modal-container mt-3">
               <div className="form-group">
                  <label>First Name</label>
                  <input name="fname" type="text" className="form-control" aria-describedby="emailHelp" placeholder="First Name" />
               </div>
               {/* <div className="form-group mt-3">
                        <label>Last Name</label>
                        <input type="text" className="form-control"  aria-describedby="emailHelp" placeholder="Last Name" />
                    </div> */}
               <div className="form-group mt-3">
                  <label>Email address</label>
                  <input name="email" type="email" className="form-control" aria-describedby="emailHelp" placeholder="Email address" />
               </div>
               <div className="form-group mt-3">
                  <label>Phone Number</label>
                  <input name="phone" type="text" className="form-control" placeholder="Phone Number" />
               </div>
               <div className="form-group mt-3">
                  <label>Register as</label>
                  <select name="user_type" className="form-control" placeholder="Phone Number">
                     <option value="">Select</option>
                     <option value="client">Client</option>
                     <option value="worker">Worker</option>
                  </select>
               </div>
               <hr className='mt-4' />
               <div className="form-check mt-3">
                  <input type="checkbox" className="form-check-input" />
                  <label className="form-check-label">Yes, I want to receive newsletter and I know that I can unsubscribe anytime.</label>
               </div>
               <button id="submit_btn" className='btn btn-warning pre-reg-btn px-sm-5 py-sm-3 bg-base-secondary w-100 mt-3 fw-bold'>
                  Register
               </button>
            </form>
         </div>
      </div>
   );
}

export default PreRegister;
