import React, { useEffect } from 'react'
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";
import './App.css';
import DefaultLayout from "./components/defaultLayout";
import Home from "./pages/home";
import ContactUs from "./pages/contactUs";
import PreRegister from "./pages/preRegister";
import Privacy from './pages/Privacy';

function App() {
   useEffect(() => {
      AOS.init({
         duration: 2000
      });
   }, [])
   return (
      <div>
         <Router>
            <DefaultLayout>
               <Switch>
                  <Route path="/contact-us">
                     <ContactUs />
                  </Route>
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/terms" component={Privacy} />

                  <Route path="/pre-register">
                     <PreRegister />
                  </Route>
                  <Route path="/">
                     <Home />
                  </Route>
               </Switch>
            </DefaultLayout>
         </Router>
      </div>
   );
}

export default App;
