import React from 'react'
import { Link } from "react-router-dom";
import logo from '../assets/images/hollajobslogo.png'

function Header() {
   return (
      <header>
         <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <Link className="navbar-brand" to='/'>
               <img src={logo} width="80" className="d-inline-block align-top" alt="" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse d-sm-flex justify-content-sm-end" id="navbarSupportedContent">
               <ul className="navbar-nav mr-auto">
                  {/* <li className="nav-item px-sm-4">
                     <a className="nav-link text-dark" href="#">How it Works</a>
                  </li> */}
                  {/* <li className="nav-item px-sm-4">
                     <Link className="nav-link text-dark" to='/contact-us'>
                        Contact Us
                     </Link>
                  </li> */}
               </ul>
            </div>
         </nav>
      </header>
   );
}

export default Header;
